



































import { TelegramChannel } from "@/store/types";
import Vue from "vue"

export default Vue.extend({
  name: "ChannelsForm",

  props: {
    channel: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        id: "",
        active: true,
        chat_id: "",
        link: "",
        status: "pending",
        type: "activation",
        title: "",
        uid: "", // TODO pegar o id do usuário logado
        username: "",
        created_at: new Date(),
        updated_at: new Date(),
      } as TelegramChannel
    };
  },

  mounted() {
    if (this.channel) {
      Object.assign(this.payload, this.channel)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      if (this.channel) {
        this.payload.updated_at = new Date()
        // this.$store.dispatch("telegramChannels/update", this.payload);
      } else {
        this.$store.dispatch("telegramChannels/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    isFloatNumber(value: any): Boolean {
      return isNaN(parseFloat(value));
    },

    isValidUrl(string: string): boolean {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }

      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    }
  }
})
