







































import Vue from "vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { TelegramChannel } from "@/store/types";

export default Vue.extend({
  name: "ChannelsTable",

  components: {
    ConfirmDialog,
  },

  props: {
    items: { type: Array, default: null },
  },

  data: () => ({
    showDeleteDialog: false,
    channelToDelete: null as unknown | TelegramChannel,
    headers: [
      {
        text: "Título",
        value: "title",
        align: "start"
      },
      {
        text: "Usuário",
        value: "username",
        align: "start"
      },
      {
        text: "Condição",
        value: "status",
        align: "center"
      },
      {
        text: "Status",
        value: "info",
        align: "center"
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  methods: {
    showDelDialog(item: TelegramChannel): void {
      this.showDeleteDialog = true
      this.channelToDelete = item;
    },

    onEdit(item: TelegramChannel): void {
      this.$emit("onEdit", item)
    },

    onDelete(): void {
      this.$emit("onDelete", this.channelToDelete)
      this.showDeleteDialog = false
    }
  }
})
