

























import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import ChannelsForm from "./components/ChannelsForm.vue"
import ChannelsTable from "./components/ChannelsTable.vue"
import { TelegramChannel } from "@/store/types";

export default Vue.extend({
  name: "TelegramChannels",
  
  components: {
    FullScreenDialog,
    ChannelsForm,
    ChannelsTable
  },

  data: () => ({
    fullScreenDialog: false,
    channel: null as unknown | TelegramChannel
  }),

  computed: {
    ...mapGetters({
      channels: "telegramChannels/getChannels",
    })
  },

  mounted() {
    this.$store.dispatch("telegramChannels/fetch");
  },

  methods: {
    editCol(channel: TelegramChannel): void {
      this.channel = channel;
      this.openFullScreenDialog();
    },

    deleteCol(channel: TelegramChannel): void {
      this.$store.dispatch("telegramChannels/delete", channel);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.channel = null;
    },
  }
})
